<template>
  <v-row>
    <v-col>
      <base-card>
        <v-card-title>Cliente</v-card-title>
        <v-card-text>
          <v-stepper-icon v-model="e1">
            <v-stepper-header>

              <v-stepper-step-icon
               icon="mdi-office-building"
                :complete="e1 > 1"
                step="1"
                
              >
              Datos de la Empresa
              </v-stepper-step-icon>


              <v-divider />

              <v-stepper-step-icon
              icon="mdi-office-building-marker"
                :complete="e1 > 2"
                step="2"
              >
                Dirección
              </v-stepper-step-icon>
              <v-divider />
           

            
           
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card
                  class="mb-12"
                >
                <v-row>
                          <v-col cols="8">

                  <v-text-field
                                v-model="cliente.nombre"
                                label="Razón Social"
                                class="uppercase"
                                
                            />
                          </v-col>
                          <v-col cols="4">
                             <v-text-field
                                v-model="cliente.rfc"
                                label="RFC"
                                class="uppercase"
                                
                            />
                          </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                   <v-text-field
                                v-model="cliente.telefono"
                                label="Teléfono"
                                class="uppercase"
                                
                            />
                  </v-col>
                </v-row>
                </v-card>

                <v-btn
                  color="primary"
                  @click="e1 = 2"
                >
                  Continuar
                </v-btn>

                
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card
                  class="mb-12"
                  
                >
                <v-row>
                  <v-col cols="2">
                      <v-text-field
                                 v-model="cliente.calle"
                                label="Calle"
                                class="uppercase"
                                
                            />
                  </v-col>
                  <v-col cols="2"
                  >
                   <v-text-field
                                 v-model="cliente.numero"
                                label="Número"
                                class="uppercase"
                                
                            />
                  </v-col>
                    <v-col cols="4"
                  >
                      <v-text-field
                                 v-model="cliente.cruzamientos"
                                label="Cruzamientos"
                                class="uppercase"
                                
                            />
                  </v-col>
                   
                </v-row>
                <v-row>
                     <v-col cols="4"
                  >
                      <v-text-field
                                 v-model="cliente.colonia"
                                label="Colonia"
                                class="uppercase"
                                
                            />
                            
                  </v-col>
                     <v-col cols="2">
                        <v-text-field
                                 v-model="cliente.cp"
                                label="Código Postal"
                                class="uppercase"
                                
                            />
                   </v-col>
                </v-row>
                <v-row>
                
                     <v-col cols="4">
                        <v-text-field
                                 v-model="cliente.localidad"
                                label="Localidad"
                                class="uppercase"
                                
                            />
                   </v-col>
                     <v-col cols="4">
                        <v-text-field
                                 v-model="cliente.municipio"
                                label="Municipio"
                                class="uppercase"
                                
                            />
                   </v-col>
                     <v-col cols="4">
                        <v-text-field
                                 v-model="cliente.estado"
                                label="Estado"
                                class="uppercase"
                                
                            />
                   </v-col>
                  </v-row>
                </v-card>

               

              <v-btn
                  color="success"
                 @click="saveCliente" 
                >
                  Finalizar
                </v-btn>

                <v-btn text @click="e1=1">
                  Regresar
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="3"  >
                <v-card
                 
                 
                 
                >
               
                
                <v-row>
                  <v-col cols="2">
                    <v-btn color="info" v-show="edit"  @click.stop="showScheduleForm=true">Nuevo Sitio</v-btn>

                  </v-col>
                     <v-col cols="2">
                    <v-btn color="info" @click="addRuta()">Nueva Ruta</v-btn>

                  </v-col>
                </v-row>
                 <v-col cols="12" offset="2">
                <v-row      v-for="(ruta, index_ruta) in cliente.lista_rutas" :key="ruta.idruta">
            
                <v-btn v-show="cliente.lista_rutas.length>1" class="btn btn-danger" @click="deleteRuta(index_ruta)">
                  Eliminar Ruta
                </v-btn>
              <label class="col-sm-2 col-form-label">Ruta:</label>
              <v-col cols="2">
                <input
                  type="nombre"
                  v-model="ruta.descripcion"
                  class="form-control"
                  placeholder="Nombre de la ruta"
                  @input="
                   ruta.descripcion = $event.target.value.toUpperCase()
                  "
                />
                
              </v-col>
                 <v-col cols="2">
                <v-btn color="info"   @click="addTramo(ruta)">
               <v-icon>mdi-plus</v-icon>

                </v-btn>
              </v-col>
                
             


 </v-row>
                </v-col>
                 </v-card>
<br>
                <v-btn
                  color="success"
                 @click="saveCliente" 
                >
                  Finalizar
                </v-btn>

                <v-btn text @click="e1=2">
                  Regresar
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper-icon>
        </v-card-text>
      </base-card>
    </v-col>


  </v-row>
 

</template>
<script>
import { url } from "../../main.js";
import VStepperStepIcon from '../../components/VStepperStepIcon.vue';
import VStepperIcon from '../../components/VStepperIcon.vue'

  export default {
  components: { VStepperStepIcon,VStepperIcon},
  
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Cliente",
    },
    data () {
      return {
         showScheduleForm: false,
              edit: false,
           enviar: {},
        idcliente: this.$route.params.idcliente,
        e1: 1,
        e6: 1,
        steps: 2,
       cliente: {
        idcliente: 0,

        nombre: "",
        rfc: "",

        telefono: "",
        direccion: {
          calle: "",
          cruzamientos: "",
          numero: "",
          colonia: "",
          cp: "",
          localidad: "",
          municipio: "",
          estado: "",
        },
       
        lista_rutas:[],
        borrar_ruta:[],
       
        borrar_tramo:[]
      },
        ubicacion_origen: {
        idubicaciones: 0,
        descripcion: "",
        idcarga: 1,
        idruta_ubicacion: 0,
      },
      ubicacion_destino: {
        idubicaciones: 0,
        descripcion: "",
        idcarga: 2,
        idruta_ubicacion: 0,
      },
ruta: {
          idruta: 0,
          descripcion: "",
          lista_tramo: [],
          i:0
        },
      busqueda_nombre: "",
      busqueda_apellidos: "",
      lista_clientes: [],
      lista_ubicaciones: [],
      }
    },
    watch: {
      steps (val) {
        if (this.e1 > val) {
          this.e1 = val
        }
      },
    },mounted(){
this.permiso = localStorage.getItem("permission");
    console.log(this.idcliente)
    if(this.idcliente==0)
    {


    }else{
    this.editCliente();
    //this.verUbicaciones();
    console.log(this.ruta);
   // this.cliente.lista_rutas.push(this.ruta);
    }
    },

    methods: {
      nextStep (n) {
        if (n === this.steps) {
          this.e1 = 1
        } else {
          this.e1 = n + 1
        }
      },
       uppercase(val) {
       val = val.toUpperCase();
       return val
    },

    verClientes() {
      this.axios
        .get(url + "apiClientes.php/clientes")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_clientes = respuesta.data;

          console.log(this.lista_clientes);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },

    saveCliente() {
      if (this.validar()) {
        this.enviar.cliente = this.cliente;
        //  this.enviar.cliente.lista_tramo = this.lista_tramo;
        console.log(this.cliente);

        // if (this.validarcampos()) {
        //    if (this.cliente != null) {
        this.axios
          .post(url + "apiClientes.php/save_cliente", this.enviar)
          .then((respuesta) => {
            console.log(respuesta);
            if (respuesta.data.status === 200) {
              this.$swal(respuesta.data.msg);
			  this.$router.push("/catalogos/clientes");

            
            }
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      } else {
        this.$swal("Debe ingresar los datos de la empresa");
      }
      //   }
      // }else{
      //  this.$swal("Debe ingresar nombre y apellido");
      //}
      //  }
    
    },
     

     limpiarcampos() {
      this.cliente.idcliente = 0;
      this.cliente.nombre = "";
      this.cliente.rfc = "";
      this.cliente.telefono = "";
      this.cliente.direccion.calle = "";
      this.cliente.direccion.cruzamientos = "";
      this.cliente.direccion.numero = "";
      this.cliente.colonia = "";
      this.cliente.cp = "";
      this.cliente.localidad = "";
      this.cliente.municipio = "";
      this.cliente.estado = "";
      this.cliente.pais = "";
   //   this.cliente.ruta.idruta = 0;
    //  this.cliente.ruta.descripcion = "";
   //   this.cliente.lista_tramo.length = 0;

      this.nombre_cliente = "";
    },
     validar() {
      if (
        this.cliente.rfc != "" &&
        this.cliente.nombre != ""
      ) {
        return true;
      }
      return false;
    },
    cancelarEdicion() {
      this.edit = false;
      this.cliente = {};
    },
    editCliente() {
      this.axios
        .get(url + "apiClientes.php/cliente/" + this.idcliente)
        .then((respuesta) => {
          console.log(respuesta);
          this.cliente = respuesta.data;
          //  this.lista_tramo = this.cliente.lista_tramo;
         // this.i = this.cliente.lista_rutas.length;
          this.edit = true;
        });
    }
    }
  }
</script>
